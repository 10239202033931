export const awsConfig = {
  COOKIE_STORAGE: {
    development: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: 'localhost',
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: 'strict',
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: false,
    },
    test: {
      domain: '.expereo.one',
      path: '/',
      expires: 1,
      sameSite: 'strict',
      secure: true,
    },
    acceptance: {
      domain: '.expereo.one',
      path: '/',
      expires: 1,
      sameSite: 'strict',
      secure: true,
    },
    production: {
      domain: '.expereo.one',
      path: '/',
      expires: 1,
      sameSite: 'strict',
      secure: true,
    },
  },
  REDIRECT_URI: {
    development: 'http://localhost:3001/',
    test: 'https://old-developer-test.dev.expereo.one/',
    acceptance: 'https://old-developer-accp.dev.expereo.one/',
    production: 'https://developer.expereo.one/',
  },
  USERPOOL_ID: {
    development: 'eu-central-1_EFcfDuejS',
    test: 'eu-central-1_EFcfDuejS',
    acceptance: 'eu-central-1_QZw0AWvfo',
    production: 'eu-central-1_0yHgv7T1n',
  },
  WEBCLIENT_ID: {
    development: '4ntkcaje3ah18spju1kkmjcmn2',
    test: '4ntkcaje3ah18spju1kkmjcmn2',
    acceptance: '4e3hrqgphi1es437rvaq5cnf7v',
    production: '251hnc1mesq821hno5v63oehfr',
  },
  AUTH_DOMAIN: {
    development: 'auth-test.expereo.one',
    test: 'auth-test.expereo.one',
    acceptance: 'auth-accp.expereo.one',
    production: 'auth.expereo.one',
  },
}

const GTM_ID = 'GTM-KCB87T4'

// INFO: Merge Request env for tests run on Gitlab CI/CD
const CI_CD_MR = 'ci_cd_mr'
const DEVELOPMENT = 'development'
const TEST = 'test'
const ACCEPTANCE = 'acceptance'
const PRODUCTION = 'production'

export const ENVIRONMENTS = {
  DEVELOPMENT,
  CI_CD_MR,
  TEST,
  ACCEPTANCE,
  PRODUCTION,
}

export const config = {
  PORTAL_URL: {
    [DEVELOPMENT]: 'http://localhost:3001',
    [CI_CD_MR]: 'http://localhost:3001',
    [TEST]: 'https://old-developer-test.dev.expereo.one',
    [ACCEPTANCE]: 'https://old-developer-accp.dev.expereo.one',
    [PRODUCTION]: 'https://developer.expereo.one',
  },
  API: {
    [DEVELOPMENT]: 'https://api-test.expereocloud.com/graphql',
    [CI_CD_MR]: 'https://api-test.expereocloud.com/graphql',
    [TEST]: 'https://api-test.expereocloud.com/graphql',
    [ACCEPTANCE]: 'https://api-accp.expereocloud.com/graphql',
    [PRODUCTION]: 'https://api.expereo.one/graphql',
  },
  AUTH_SERVERS: {
    [DEVELOPMENT]: 'https://auth-test.expereo.one/oauth2/token',
    [CI_CD_MR]: 'https://auth-test.expereo.one/oauth2/token',
    [TEST]: 'https://auth-test.expereo.one/oauth2/token',
    [ACCEPTANCE]: 'https://auth-accp.expereo.one/oauth2/token',
    [PRODUCTION]: 'https://auth.expereo.one/oauth2/token',
  },
  EXPEREO_URL: {
    [DEVELOPMENT]: 'http://localhost:3000',
    [CI_CD_MR]: 'https://test.dev.expereo.one',
    [TEST]: 'https://test.dev.expereo.one',
    [ACCEPTANCE]: 'https://accp.dev.expereo.one',
    [PRODUCTION]: 'https://www.expereo.one',
  },
  GTM: {
    [DEVELOPMENT]: { auth: 'toSKLxJntePJwnn6O61u3g', preview: 'env-3' },
    [CI_CD_MR]: { auth: 'toSKLxJntePJwnn6O61u3g', preview: 'env-3' },
    [TEST]: { auth: 'toSKLxJntePJwnn6O61u3g', preview: 'env-3' },
    [ACCEPTANCE]: { auth: 'HOC9IwVd9aoLcCEv-pN2LQ', preview: 'env-4' },
    [PRODUCTION]: { auth: 'JZGUydN27hTHzzv1qzNmPA', preview: 'env-1' },
  },
  GTM_ID,
}

import React from 'react'
import { Redirect, Route } from 'react-router-dom'

function PrivateRoute({ children, user, ...rest }) {
  const isDeveloper =
    !!user &&
    typeof user.attributes['custom:sf_permissions'] === 'string' &&
    user.attributes['custom:sf_permissions'].includes('DeveloperPortalAPI')

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isDeveloper ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                shouldBlockAccessToNotDevelopers: !isDeveloper,
                from: location,
              },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute

import React from 'react'

const DevLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="158" height="150" fill="none">
    <g clipPath="url(#a)">
      <path
        fill="#F5F5F5"
        d="M23.09 133.837c30.784 20.551 80.701 20.551 111.492 0 30.79-20.552 30.787-53.872 0-74.423-30.788-20.552-80.7-20.552-111.492 0-30.79 20.55-30.783 53.889 0 74.423Z"
      />
      <path
        fill="#EBEBEB"
        d="M21.342 99.622c4.368 0 7.908-2.156 7.908-4.815 0-2.659-3.54-4.814-7.908-4.814-4.367 0-7.907 2.155-7.907 4.814 0 2.66 3.54 4.815 7.907 4.815ZM111.582 106.05c5.828 0 10.553-2.878 10.553-6.428s-4.725-6.428-10.553-6.428c-5.829 0-10.554 2.878-10.554 6.428s4.725 6.428 10.554 6.428Z"
      />
      <path
        fill="#30B800"
        d="M143.133 73.174s.815-3.218 3.468-6.648c1.17-1.512 1.934-2.568 1.69-3.47-.22-.795-.964-1.208-2.027-.566-1.511.914-2.529 2.611-3.131 6.02v4.664Z"
      />
      <path
        fill="#000"
        d="M143.133 73.174s.815-3.218 3.468-6.648c1.17-1.512 1.934-2.568 1.69-3.47-.22-.795-.964-1.208-2.027-.566-1.511.914-2.529 2.611-3.131 6.02v4.664Z"
        opacity=".1"
      />
      <path
        fill="#fff"
        d="M142.964 71.152h-.031a.143.143 0 0 1-.079-.077.214.214 0 0 1-.014-.126c.947-4.67 3.393-7.562 4.429-8.23a.107.107 0 0 1 .099-.008c.033.014.06.043.076.082.015.04.017.084.006.125a.163.163 0 0 1-.064.095c-1.001.646-3.372 3.46-4.295 8.02a.17.17 0 0 1-.048.087.111.111 0 0 1-.079.032Z"
      />
      <path
        fill="#43FA00"
        d="M143.133 77.171s1.132-2.836 2.498-4.344c1.366-1.508 3.121-3.047 1.634-4.225-1.486-1.179-3.933 1.134-4.129 3.416l-.003 5.153Z"
      />
      <path
        fill="#fff"
        d="M142.964 75.153h-.027a.138.138 0 0 1-.08-.074.21.21 0 0 1-.017-.124c.644-3.517 2.953-5.505 3.72-5.835a.108.108 0 0 1 .093.02c.028.02.048.054.058.093.01.04.008.082-.005.12a.15.15 0 0 1-.067.084c-.688.299-2.938 2.255-3.548 5.593a.17.17 0 0 1-.047.09.112.112 0 0 1-.08.033Z"
      />
      <path
        fill="#30B800"
        d="M42.495 105.676s-.523-1.486-2.708-2.114c-2.127-.612-3.183 0-4.618-.317-1.435-.317-1.06-3.742 2.8-3.386 3.535.326 4.526 3.878 4.526 3.878v1.939Z"
      />
      <path
        fill="#fff"
        d="M42.705 104.859a.114.114 0 0 1-.07-.029.166.166 0 0 1-.047-.072c-.345-1.051-1.618-2.766-3.41-3.289-2.227-.647-3.41 0-3.421 0a.104.104 0 0 1-.094-.006.15.15 0 0 1-.067-.084.209.209 0 0 1-.005-.12.167.167 0 0 1 .06-.094c.051-.026 1.276-.708 3.581-.035 1.993.58 3.263 2.445 3.593 3.487a.2.2 0 0 1-.027.18.13.13 0 0 1-.042.035l-.051.027Z"
      />
      <path
        fill="#43FA00"
        d="M42.495 103.984a10.698 10.698 0 0 0-1.652-2.669c-2.065-2.56-4.817-3.364-6.194-4.762-1.514-1.508.32-3.25 2.268-3.14 1.115.066 2.292 1.007 2.938 1.684 2.279 2.38 2.654 6.218 2.654 6.218l-.014 2.669Z"
      />
      <path
        fill="#fff"
        d="M42.495 103.984a10.698 10.698 0 0 0-1.652-2.669c-2.065-2.56-4.817-3.364-6.194-4.762-1.514-1.508.32-3.25 2.268-3.14 1.115.066 2.292 1.007 2.938 1.684 2.279 2.38 2.654 6.218 2.654 6.218l-.014 2.669Z"
        opacity=".5"
      />
      <path
        fill="#fff"
        d="M42.705 103.724a.116.116 0 0 1-.084-.04.187.187 0 0 1-.044-.1c-.27-1.849-.927-3.57-1.9-4.982-1.623-2.361-4.4-3.628-4.431-3.64a.149.149 0 0 1-.072-.084.209.209 0 0 1-.007-.123.176.176 0 0 1 .025-.057.138.138 0 0 1 .04-.039.102.102 0 0 1 .1-.006c.117.049 2.87 1.32 4.539 3.729 1.012 1.457 1.691 3.241 1.965 5.158a.21.21 0 0 1-.004.065.183.183 0 0 1-.023.058.15.15 0 0 1-.038.042.101.101 0 0 1-.05.019h-.016Z"
      />
      <path
        fill="#91E509"
        d="M89.72 131.311s.585-1.451 2.794-1.931c2.148-.466 3.177.198 4.625 0 1.449-.197 1.215-3.667-2.656-3.574-3.541.083-4.68 3.561-4.68 3.561l-.083 1.944Z"
      />
      <path
        fill="#000"
        d="M89.72 131.311s.585-1.451 2.794-1.931c2.148-.466 3.177.198 4.625 0 1.449-.197 1.215-3.667-2.656-3.574-3.541.083-4.68 3.561-4.68 3.561l-.083 1.944Z"
        opacity=".1"
      />
      <path
        fill="#fff"
        d="M89.552 130.616a.132.132 0 0 1-.111-.077.194.194 0 0 1-.017-.124.18.18 0 0 1 .02-.058 6.17 6.17 0 0 1 4.01-3.017c2.171-.497 3.44.383 3.496.44.015.01.028.024.038.041a.204.204 0 0 1 .012.181.167.167 0 0 1-.032.049.126.126 0 0 1-.045.029.108.108 0 0 1-.097-.014s-1.25-.858-3.328-.383c-1.62.37-3.059 1.447-3.84 2.88a.14.14 0 0 1-.049.041.107.107 0 0 1-.057.012Z"
      />
      <path
        fill="#43FA00"
        d="M89.875 129.275a12.9 12.9 0 0 1 1.693-2.177c2.178-2.409 4.935-2.801 6.394-4.102 1.576-1.403-.183-3.267-2.134-3.289-1.115 0-2.33.849-3.007 1.482-1.445 1.383-2.525 3.295-3.097 5.483l.151 2.603Z"
      />
      <path
        fill="#fff"
        d="M89.875 129.275a12.9 12.9 0 0 1 1.693-2.177c2.178-2.409 4.935-2.801 6.394-4.102 1.576-1.403-.183-3.267-2.134-3.289-1.115 0-2.33.849-3.007 1.482-1.445 1.383-2.525 3.295-3.097 5.483l.151 2.603Z"
        opacity=".5"
      />
      <path
        fill="#fff"
        d="M89.552 129.24h-.049a.153.153 0 0 1-.072-.083.215.215 0 0 1-.01-.124c.538-1.887 1.387-3.604 2.491-5.034 1.197-1.578 2.753-2.635 4.446-3.021a.104.104 0 0 1 .05.003.127.127 0 0 1 .044.029.15.15 0 0 1 .032.048.19.19 0 0 1 .015.061.21.21 0 0 1-.003.065.183.183 0 0 1-.023.058.15.15 0 0 1-.039.042.113.113 0 0 1-.049.019c-1.63.396-3.127 1.417-4.29 2.929-1.07 1.39-1.891 3.06-2.41 4.893a.169.169 0 0 1-.05.087.109.109 0 0 1-.083.028Z"
      />
      <path
        fill="#37474F"
        d="M56.47 106.046v-33.22H42.494v33.224c0 1.319.688 2.638 2.065 3.641 2.728 2.014 7.154 2.014 9.882 0 1.377-1.007 2.044-2.322 2.048-3.641l-.02-.004Z"
      />
      <path
        fill="#455A64"
        d="M44.542 76.696c-2.732-2.014-2.732-5.276 0-7.294 2.733-2.019 7.154-2.014 9.883 0 2.729 2.013 2.729 5.276 0 7.294s-7.154 2.01-9.883 0Z"
      />
      <path
        fill="#37474F"
        d="M73.244 118.423V94.24H59.267v24.183c0 1.319.688 2.638 2.064 3.641 2.73 2.014 7.154 2.014 9.883 0 1.377-1.003 2.048-2.322 2.048-3.641h-.018Z"
      />
      <path
        fill="#455A64"
        d="M61.314 98.114c-2.728-2.014-2.728-5.276 0-7.295 2.73-2.018 7.154-2.014 9.883 0 2.729 2.014 2.732 5.277 0 7.295s-7.157 2.031-9.883 0Z"
      />
      <path
        fill="#37474F"
        d="M90.02 130.796v-18.779H76.042v18.784c0 1.319.688 2.638 2.065 3.645 2.728 2.014 7.154 2.014 9.883 0 1.376-1.007 2.044-2.326 2.047-3.645l-.017-.005Z"
      />
      <path
        fill="#455A64"
        d="M83.03 117.39c3.86 0 6.99-2.309 6.99-5.158 0-2.848-3.13-5.157-6.99-5.157-3.859 0-6.988 2.309-6.988 5.157 0 2.849 3.13 5.158 6.989 5.158Z"
      />
      <path
        fill="#30B800"
        d="m41.6 117.76-3.3-2.432 1.827-3.025c.234-.387.124-.598-.244-.479l-4.377 1.442-3.3-2.436a1.637 1.637 0 0 0-.895-.274c-.31 0-.617.094-.894.274l-5.2 3.825c-.495.365-.495.954 0 1.319l9.394 6.93c.278.18.584.274.895.274.311 0 .617-.094.895-.274l5.2-3.839c.491-.351.491-.941 0-1.305Z"
      />
      <path
        fill="#fff"
        d="M36.322 116.269a.625.625 0 0 1 .161 0 .335.335 0 0 1 .152.066l.471.347a.17.17 0 0 1 .069.097c0 .035 0 .066-.048.088a.05.05 0 0 1-.035 0 .09.09 0 0 1-.051 0l-6.538.659h-.186a.353.353 0 0 1-.151-.07l-.472-.347a.17.17 0 0 1-.069-.097c0-.035 0-.066.048-.088a.05.05 0 0 1 .035 0h.051l6.563-.655Zm-2.106-.559c-.14.12-.286.227-.437.321-.122.08-.25.143-.382.19-.139.044-.28.066-.424.065a1.483 1.483 0 0 1-.905-.316 1.198 1.198 0 0 1-.344-.334.603.603 0 0 1-.1-.33.572.572 0 0 1 .093-.312c.069-.114.153-.211.248-.286a2.32 2.32 0 0 1 .203-.167c.066-.048.145-.101.23-.154.125-.08.255-.142.39-.185.285-.09.584-.09.87 0 .16.055.312.137.454.242.125.083.234.197.324.334.063.092.1.208.103.33a.635.635 0 0 1-.093.312 1.083 1.083 0 0 1-.23.29Zm.65 3.439a3.467 3.467 0 0 1-.42.343 1.651 1.651 0 0 1-.385.184 1.21 1.21 0 0 1-.423.071 1.578 1.578 0 0 1-.905-.317 1.249 1.249 0 0 1-.324-.334.6.6 0 0 1-.01-.646 1.07 1.07 0 0 1 .251-.282c.064-.062.132-.118.203-.167.074-.057.151-.109.23-.154a1.538 1.538 0 0 1 .809-.255c.317 0 .629.11.902.317.131.08.248.194.344.334.06.089.094.203.094.321a.574.574 0 0 1-.094.321c-.078.107-.17.196-.272.264Zm-2.192-3.957a.49.49 0 0 0-.162.211c0 .061 0 .123.1.184a.343.343 0 0 0 .248.075c.1-.02.196-.062.285-.123.13-.08.256-.172.376-.277a.48.48 0 0 0 .165-.211c0-.062-.017-.123-.1-.185a.33.33 0 0 0-.251-.07.72.72 0 0 0-.282.119 2.54 2.54 0 0 0-.379.259v.018Zm.654 3.438a.48.48 0 0 0-.165.211c0 .057 0 .119.1.18a.316.316 0 0 0 .25.075.792.792 0 0 0 .283-.119c.132-.079.258-.171.378-.277a.464.464 0 0 0 .162-.211c0-.061 0-.123-.1-.184a.343.343 0 0 0-.247-.075.742.742 0 0 0-.283.123c-.131.073-.258.16-.378.259v.018Z"
      />
      <path
        fill="#30B800"
        d="m59.776 131.161-3.307-2.436 1.827-3.02c.231-.387.12-.603-.247-.48l-4.377 1.438-3.3-2.431a1.632 1.632 0 0 0-.893-.274c-.31 0-.617.094-.893.274l-5.193 3.834c-.492.365-.492.958 0 1.319l9.394 6.934c.278.178.584.27.895.27.31 0 .617-.092.895-.27l5.2-3.839c.495-.365.495-.954 0-1.319Z"
      />
      <path
        fill="#fff"
        d="M54.498 129.671h.165a.38.38 0 0 1 .151.07l.468.347c.041.031.066.062.069.097.003.035 0 .062-.045.088a.067.067 0 0 1-.034 0h-.052l-6.538.66a.76.76 0 0 1-.165 0 .341.341 0 0 1-.152-.071l-.468-.343c-.04-.03-.065-.066-.068-.096a.116.116 0 0 1 .01-.056.087.087 0 0 1 .034-.037h.086l6.538-.659Zm-2.106-.559a3.271 3.271 0 0 1-.434.321 1.696 1.696 0 0 1-.385.185c-.138.048-.28.072-.424.07-.152 0-.304-.027-.45-.079a1.55 1.55 0 0 1-.455-.237 1.297 1.297 0 0 1-.327-.334.568.568 0 0 1-.096-.324c0-.119.034-.234.096-.323a1.11 1.11 0 0 1 .248-.281c.065-.061.133-.117.203-.167.076-.057.154-.108.234-.154a1.61 1.61 0 0 1 .386-.185c.222-.072.454-.088.681-.046.228.043.446.142.643.292.125.084.236.198.327.334a.593.593 0 0 1 .1.33.594.594 0 0 1-.093.312c-.07.115-.157.212-.254.286Zm.653 3.439c-.14.119-.285.227-.437.321-.121.079-.25.141-.382.184-.138.045-.28.067-.423.066a1.484 1.484 0 0 1-.905-.316 1.217 1.217 0 0 1-.327-.33.605.605 0 0 1-.1-.334.623.623 0 0 1 .093-.312c.07-.113.154-.209.248-.286.069-.062.138-.114.203-.167.066-.053.145-.101.23-.154.125-.08.255-.142.39-.185.139-.04.28-.062.423-.066.15 0 .301.025.447.075.16.052.313.134.454.242.125.084.236.197.327.334a.56.56 0 0 1 .097.321.56.56 0 0 1-.097.321 1.15 1.15 0 0 1-.24.286Zm-2.192-3.958a.487.487 0 0 0-.165.211c0 .058.017.119.1.181a.32.32 0 0 0 .251.075.834.834 0 0 0 .282-.119c.133-.079.26-.173.379-.282a.455.455 0 0 0 .162-.206c0-.062-.018-.123-.1-.185a.325.325 0 0 0-.248-.075.757.757 0 0 0-.286.123c-.13.074-.255.16-.375.26v.017Zm.65 3.439a.515.515 0 0 0-.161.207c0 .061.017.123.1.184.074.06.16.086.248.075.1-.02.196-.06.285-.119.13-.082.256-.176.375-.281a.5.5 0 0 0 .165-.207c.018-.061 0-.123-.1-.184a.333.333 0 0 0-.25-.075.794.794 0 0 0-.283.119c-.13.077-.257.165-.378.263v.018Z"
      />
      <path
        fill="#30B800"
        d="m77.938 144.554-3.3-2.431 1.83-3.03c.231-.382.121-.598-.247-.475l-4.377 1.438-3.3-2.431a1.637 1.637 0 0 0-.895-.274c-.31 0-.617.094-.894.274l-5.2 3.851c-.492.365-.492.959 0 1.319l9.394 6.934c.278.178.584.271.895.271.31 0 .617-.093.895-.271l5.2-3.856c.495-.351.495-.941 0-1.319Z"
      />
      <path
        fill="#fff"
        d="M72.66 143.077h.161c.054.01.105.034.151.07l.472.348c.041.03.065.061.069.096.003.036 0 .062-.045.088h-.086l-6.538.66a.625.625 0 0 1-.162 0 .32.32 0 0 1-.151-.071l-.468-.342c-.041-.031-.066-.066-.069-.097a.115.115 0 0 1 .01-.056.087.087 0 0 1 .035-.037h.034a.09.09 0 0 1 .052 0l6.534-.659Zm-2.107-.558a3.158 3.158 0 0 1-.437.32c-.121.08-.25.142-.382.185-.137.048-.28.072-.423.07-.153 0-.306-.027-.454-.079a1.528 1.528 0 0 1-.45-.237 1.294 1.294 0 0 1-.328-.334.603.603 0 0 1-.1-.334.626.626 0 0 1 .093-.313c.07-.111.154-.207.248-.285.069-.062.138-.115.203-.163.066-.048.145-.101.23-.158.254-.159.531-.244.813-.251.317.001.628.111.901.317.125.084.236.197.327.334a.612.612 0 0 1 .1.33.637.637 0 0 1-.093.312 1.08 1.08 0 0 1-.248.286Zm.65 3.438c-.138.119-.283.226-.433.321-.122.079-.25.141-.382.184a1.4 1.4 0 0 1-.877 0 1.565 1.565 0 0 1-.451-.241 1.155 1.155 0 0 1-.344-.334.582.582 0 0 1-.1-.33.604.604 0 0 1 .09-.312c.071-.112.156-.208.25-.286.066-.061.134-.116.204-.167.065-.049.144-.101.23-.154.251-.165.53-.251.812-.251.151 0 .302.025.448.075.158.053.31.135.45.242.132.08.249.194.345.334.063.09.099.208.1.33a.607.607 0 0 1-.09.312c-.07.11-.156.204-.251.277ZM69.013 142a.488.488 0 0 0-.162.211c0 .057.017.118.1.18.074.059.16.085.248.075.1-.02.196-.06.285-.119.132-.08.26-.174.379-.281a.464.464 0 0 0 .162-.207c.017-.062 0-.123-.1-.185a.336.336 0 0 0-.252-.074.76.76 0 0 0-.282.123 2.747 2.747 0 0 0-.378.259V142Zm.654 3.438a.536.536 0 0 0-.166.207c0 .061 0 .123.104.184.073.06.16.086.247.075a.792.792 0 0 0 .283-.119c.13-.082.257-.176.378-.281a.5.5 0 0 0 .165-.207c.019-.061-.016-.123-.103-.184a.324.324 0 0 0-.248-.075.792.792 0 0 0-.282.119 2.88 2.88 0 0 0-.378.277v.004Z"
      />
      <path fill="#37474F" d="m124.964 72.018-5.592-4.124V47.267l5.592 4.125v20.626Z" />
      <path fill="#455A64" d="m124.964 72.018 5.592-4.124V47.267l-5.592 4.125v20.626Z" />
      <path fill="#455A64" d="m119.372 47.268 5.592 4.124 5.592-4.124-5.592-4.125-5.592 4.125Z" />
      <path fill="#37474F" d="m112.383 62.736-5.591-4.124V17.364l5.591 4.124v41.248Z" />
      <path fill="#455A64" d="m112.383 62.736 5.592-4.124V17.364l-5.592 4.124v41.248Z" />
      <path fill="#455A64" d="m106.792 17.364 5.591 4.124 5.592-4.124-5.592-4.124-5.591 4.124Z" />
      <path fill="#37474F" d="M99.803 53.454 94.21 49.33V34.895l5.592 4.124v14.435Z" />
      <path
        fill="#455A64"
        d="m99.803 53.454 5.592-4.124V34.895l-5.592 4.124v14.435ZM94.21 34.894l5.593 4.125 5.591-4.125-5.591-4.124-5.592 4.124Z"
      />
      <path fill="#37474F" d="m137.545 81.3-5.592-4.129V37.99l5.588 4.124.004 39.186Z" />
      <path fill="#455A64" d="m137.541 81.3 5.592-4.129V37.99l-5.592 4.124V81.3Z" />
      <path fill="#455A64" d="m131.953 37.99 5.588 4.124 5.592-4.124-5.588-4.129-5.592 4.129Z" />
      <path
        fill="#fff"
        d="m98.45 78.305-.058-45.675a2.05 2.05 0 0 0-.177-.75 1.593 1.593 0 0 0-.422-.569L55.619.335c-.344-.242-.595-.044-.595.44l.069 45.657c.01.265.07.522.175.752.104.229.25.423.423.567L97.87 78.75c.32.242.581.043.581-.445Z"
        opacity=".3"
      />
      <path
        fill="#30B800"
        d="M98.127 79.07a.611.611 0 0 1-.344-.131L55.605 47.94a1.764 1.764 0 0 1-.489-.646 2.287 2.287 0 0 1-.199-.862L54.852.774A.851.851 0 0 1 54.9.383a.645.645 0 0 1 .211-.29.443.443 0 0 1 .302-.092.471.471 0 0 1 .291.14l42.177 30.972c.201.163.369.384.489.647.12.262.188.558.2.861l.058 45.676a.94.94 0 0 1-.056.387.735.735 0 0 1-.202.299.407.407 0 0 1-.244.088ZM55.354.432a.106.106 0 0 0-.069 0 .35.35 0 0 0-.075.137.426.426 0 0 0-.014.167l.058 45.675c.01.231.063.456.154.656.092.2.22.368.373.492l42.177 30.998c.103.075.193.097.248.053.055-.044.09-.15.09-.303l-.06-45.676a1.767 1.767 0 0 0-.15-.64 1.378 1.378 0 0 0-.358-.485L55.533.506a.323.323 0 0 0-.179-.075Z"
      />
      <path
        fill="#30B800"
        d="M94.445 68.228c-1.118-1.016-1.772-1.935-2.216-8.996l-.038-.603c-.416-6.635-.688-11.014-3.056-12.821-1.135-.88-2.044 0-3.004.954-.87.849-1.765 1.72-2.773.985-1.773-1.32-2.065-3.931-2.615-8.697-.07-.59-.138-1.21-.217-1.86-.12-1.016-.224-1.961-.32-2.85-.544-5.003-.874-8.028-2.877-9.572a1.385 1.385 0 0 0-.663-.342 1.232 1.232 0 0 0-.713.074c-1.518.677-2.705 4.78-3.961 9.128-.097.321-.19.66-.29.998-.911 3.206-1.944 6.842-3.371 7.515a1.14 1.14 0 0 1-.632.102 1.23 1.23 0 0 1-.604-.26c-1.421-1.03-1.676-3.843-1.92-6.57-.244-2.726-.482-5.465-1.824-6.498a.668.668 0 0 0-.774-.084c-1.666.827-3.142 7.123-3.155 7.185l-.345-.123c.062-.269 1.539-6.565 3.359-7.475a.947.947 0 0 1 .55-.109c.187.02.368.099.527.228 1.483 1.134 1.741 4.023 1.992 6.815.252 2.792.479 5.32 1.752 6.24a.927.927 0 0 0 .46.204.854.854 0 0 0 .483-.077c1.276-.603 2.326-4.287 3.169-7.25.1-.344.193-.678.289-1.003 1.342-4.64 2.502-8.645 4.17-9.392a1.5 1.5 0 0 1 .858-.092c.288.054.562.192.801.404 2.14 1.653 2.481 4.753 3.039 9.889.096.879.2 1.829.32 2.84.079.655.148 1.275.216 1.86.527 4.634.82 7.184 2.447 8.38.809.594 1.542-.123 2.392-.95.963-.94 2.064-2 3.39-.984 2.515 1.926 2.797 6.397 3.22 13.164l.038.603c.454 7.202 1.135 7.817 2.064 8.675l-.168.365Z"
      />
      <path
        fill="#000"
        d="M94.445 68.228c-1.118-1.016-1.772-1.935-2.216-8.996l-.038-.603c-.416-6.635-.688-11.014-3.056-12.821-1.135-.88-2.044 0-3.004.954-.87.849-1.765 1.72-2.773.985-1.773-1.32-2.065-3.931-2.615-8.697-.07-.59-.138-1.21-.217-1.86-.12-1.016-.224-1.961-.32-2.85-.544-5.003-.874-8.028-2.877-9.572a1.385 1.385 0 0 0-.663-.342 1.232 1.232 0 0 0-.713.074c-1.518.677-2.705 4.78-3.961 9.128-.097.321-.19.66-.29.998-.911 3.206-1.944 6.842-3.371 7.515a1.14 1.14 0 0 1-.632.102 1.23 1.23 0 0 1-.604-.26c-1.421-1.03-1.676-3.843-1.92-6.57-.244-2.726-.482-5.465-1.824-6.498a.668.668 0 0 0-.774-.084c-1.666.827-3.142 7.123-3.155 7.185l-.345-.123c.062-.269 1.539-6.565 3.359-7.475a.947.947 0 0 1 .55-.109c.187.02.368.099.527.228 1.483 1.134 1.741 4.023 1.992 6.815.252 2.792.479 5.32 1.752 6.24a.927.927 0 0 0 .46.204.854.854 0 0 0 .483-.077c1.276-.603 2.326-4.287 3.169-7.25.1-.344.193-.678.289-1.003 1.342-4.64 2.502-8.645 4.17-9.392a1.5 1.5 0 0 1 .858-.092c.288.054.562.192.801.404 2.14 1.653 2.481 4.753 3.039 9.889.096.879.2 1.829.32 2.84.079.655.148 1.275.216 1.86.527 4.634.82 7.184 2.447 8.38.809.594 1.542-.123 2.392-.95.963-.94 2.064-2 3.39-.984 2.515 1.926 2.797 6.397 3.22 13.164l.038.603c.454 7.202 1.135 7.817 2.064 8.675l-.168.365Z"
        opacity=".4"
      />
      <path
        fill="#30B800"
        d="M88.626 43.864c.256.215.469.502.623.84.153.339.243.718.261 1.108 0 .717-.395 1.007-.88.65a2.335 2.335 0 0 1-.626-.838 3.008 3.008 0 0 1-.259-1.109c0-.7.382-.99.88-.65Z"
      />
      <path
        fill="#000"
        d="M88.626 43.864c.256.215.469.502.623.84.153.339.243.718.261 1.108 0 .717-.395 1.007-.88.65a2.335 2.335 0 0 1-.626-.838 3.008 3.008 0 0 1-.259-1.109c0-.7.382-.99.88-.65Z"
        opacity=".2"
      />
      <path
        fill="#91E509"
        d="M77.418 23.282c.257.214.47.501.623.84.153.338.242.718.258 1.108 0 .717-.392 1.007-.877.65a2.33 2.33 0 0 1-.625-.837 3.004 3.004 0 0 1-.26-1.11c0-.734.393-1.007.881-.65Z"
      />
      <path
        fill="#0A0606"
        d="M77.418 23.282c.257.214.47.501.623.84.153.338.242.718.258 1.108 0 .717-.392 1.007-.877.65a2.33 2.33 0 0 1-.625-.837 3.004 3.004 0 0 1-.26-1.11c0-.734.393-1.007.881-.65Z"
        opacity=".5"
      />
      <path
        fill="#91E509"
        d="M63.444 27.437c.257.212.471.498.625.836.154.338.243.717.26 1.108 0 .72-.393 1.011-.881.655a2.346 2.346 0 0 1-.623-.84 3.02 3.02 0 0 1-.258-1.108c-.004-.717.392-1.011.877-.65Z"
      />
      <path
        fill="#fff"
        d="M76.039 76.292V30.616a2.048 2.048 0 0 0-.175-.75 1.593 1.593 0 0 0-.42-.569L38.894 2.335c-.344-.242-.595-.044-.595.44V48.45c.011.264.071.521.175.75.103.23.247.425.42.57L75.444 76.73c.33.247.596.049.596-.44Z"
        opacity=".65"
      />
      <path
        fill="#30B800"
        d="M75.711 77.057a.612.612 0 0 1-.344-.132L38.81 49.963a1.788 1.788 0 0 1-.487-.65 2.313 2.313 0 0 1-.201-.863V2.775a.85.85 0 0 1 .05-.39.644.644 0 0 1 .212-.288.443.443 0 0 1 .302-.09.471.471 0 0 1 .29.14l36.547 26.961c.2.163.368.385.488.647a2.3 2.3 0 0 1 .2.861v45.676a.942.942 0 0 1-.056.387.735.735 0 0 1-.202.299.403.403 0 0 1-.24.079ZM38.631 2.454a.109.109 0 0 0-.07 0 .35.35 0 0 0-.075.137.428.428 0 0 0-.014.166V48.45c.01.226.062.446.15.642.09.196.212.363.36.488l36.547 26.962a.24.24 0 0 0 .12.068.21.21 0 0 0 .131-.015c.055-.04.086-.15.086-.303V30.616a1.757 1.757 0 0 0-.15-.64 1.367 1.367 0 0 0-.359-.485L38.809 2.533a.299.299 0 0 0-.179-.08Z"
      />
      <path
        fill="#30B800"
        d="M69.752 65.91V35.853a1.025 1.025 0 0 0-.088-.375.795.795 0 0 0-.212-.285l-3.596-2.638c-.165-.123-.3-.026-.3.22v30.04c.006.133.036.262.088.376a.78.78 0 0 0 .212.284l3.596 2.638c.165.14.3.04.3-.203Z"
      />
      <path
        fill="#000"
        d="M69.752 65.91V35.853a1.025 1.025 0 0 0-.088-.375.795.795 0 0 0-.212-.285l-3.596-2.638c-.165-.123-.3-.026-.3.22v30.04c.006.133.036.262.088.376a.78.78 0 0 0 .212.284l3.596 2.638c.165.14.3.04.3-.203Z"
        opacity=".3"
      />
      <path
        fill="#30B800"
        d="M62.763 60.753V45.135a1.023 1.023 0 0 0-.088-.375.793.793 0 0 0-.212-.285l-3.6-2.638c-.16-.118-.295 0-.295.22V57.68c.006.132.036.26.087.375a.806.806 0 0 0 .209.285l3.6 2.638c.165.119.299.035.299-.224Z"
      />
      <path
        fill="#fff"
        d="M62.763 60.753V45.135a1.023 1.023 0 0 0-.088-.375.793.793 0 0 0-.212-.285l-3.6-2.638c-.16-.118-.295 0-.295.22V57.68c.006.132.036.26.087.375a.806.806 0 0 0 .209.285l3.6 2.638c.165.119.299.035.299-.224Z"
        opacity=".2"
      />
      <path
        fill="#30B800"
        d="M55.78 55.6V31.729a1.023 1.023 0 0 0-.087-.376.793.793 0 0 0-.212-.284l-3.599-2.638c-.162-.123-.296-.026-.296.22v23.866c.006.132.035.26.087.375a.797.797 0 0 0 .209.285l3.6 2.638c.158.123.299.027.299-.215Z"
      />
      <path
        fill="#000"
        d="M55.78 55.6V31.729a1.023 1.023 0 0 0-.087-.376.793.793 0 0 0-.212-.284l-3.599-2.638c-.162-.123-.296-.026-.296.22v23.866c.006.132.035.26.087.375a.797.797 0 0 0 .209.285l3.6 2.638c.158.123.299.027.299-.215Z"
        opacity=".2"
      />
      <path
        fill="#30B800"
        d="M48.782 50.46v-34.2a1.02 1.02 0 0 0-.087-.375.792.792 0 0 0-.21-.284l-3.599-2.638c-.162-.123-.296 0-.296.22v34.181c.006.132.036.26.088.375a.808.808 0 0 0 .208.285l3.6 2.638c.165.123.296.022.296-.202Z"
      />
      <path
        fill="#FFA8A7"
        d="M123.099 55.925c-.048-.33-1.311-4.085-1.831-5.562l-2.632 1.574 1.008 2.95 2.725 2.696c.265-.103.489-.334.625-.642.136-.309.173-.673.105-1.016ZM117.855 95.256h-2.031v3.781h2.031v-3.781ZM110.703 90.585l-2.03.09.102 3.779 2.029-.09-.101-3.779Z"
      />
      <path
        fill="#9EFF7A"
        d="M111.21 41.103c-.735.022-1.453.28-2.089.753-.635.472-1.167 1.142-1.545 1.947-1.122 2.132-3.097 5.777-3.097 5.777l3.699 1.5 3.032-9.977Z"
      />
      <path
        fill="#EDEDED"
        d="M111.21 41.103c-.735.022-1.453.28-2.089.753-.635.472-1.167 1.142-1.545 1.947-1.122 2.132-3.097 5.777-3.097 5.777l3.699 1.5 3.032-9.977Z"
        opacity=".7"
      />
      <path
        fill="#30B800"
        d="M117.72 58.374c0 2.48.252 18.516.252 18.516.048.712.571 2.027.457 4.766-.155 3.724-.406 14.07-.406 14.07s-.953 1.21-2.299 0c0 0-1.841-14.048-2.202-17.737-.32-3.219-.857-11.96-.857-11.96l-1.882 10.293s.344 2.419.403 4.125c.058 1.706-.344 11.572-.344 11.572-.33.263-.707.412-1.095.431a1.841 1.841 0 0 1-1.118-.32s-1.631-14.414-1.841-15.957c-.21-1.543 1.431-17.799 1.431-17.799h9.501Z"
      />
      <path
        fill="#000"
        d="M117.72 58.374c0 2.48.252 18.516.252 18.516.048.712.571 2.027.457 4.766-.155 3.724-.406 14.07-.406 14.07s-.953 1.21-2.299 0c0 0-1.841-14.048-2.202-17.737-.32-3.219-.857-11.96-.857-11.96l-1.882 10.293s.344 2.419.403 4.125c.058 1.706-.344 11.572-.344 11.572-.33.263-.707.412-1.095.431a1.841 1.841 0 0 1-1.118-.32s-1.631-14.414-1.841-15.957c-.21-1.543 1.431-17.799 1.431-17.799h9.501Z"
        opacity=".2"
      />
      <path
        fill="#43FA00"
        d="M114.665 41.182c1.82.559 4.129 1.5 4.941 3.645a485.315 485.315 0 0 1 2.065 5.624l-3.015 2.278-.936-2.058v7.703c-1.424 1.979-6.393 2.902-9.504 0v-12.68c0-2.019.881-4.45 3.307-4.63l3.142.118Z"
      />
      <path
        fill="#fff"
        d="M114.665 41.182c1.82.559 4.129 1.5 4.941 3.645a485.315 485.315 0 0 1 2.065 5.624l-3.015 2.278-.936-2.058v7.703c-1.424 1.979-6.393 2.902-9.504 0v-12.68c0-2.019.881-4.45 3.307-4.63l3.142.118Z"
        opacity=".7"
      />
      <path
        fill="#263238"
        d="M115.349 30.832a.861.861 0 0 1 .484.07c.155.07.296.184.411.334.385.502.306 1.434 0 3.57l-1.191.177.296-4.151ZM108.429 30.234a1.492 1.492 0 0 1-.743-.19c-.365-.268-.461.26-.261.722.132.33.328.61.568.815.241.206.519.33.808.359 0-.066.344-1.187.344-1.187l-.716-.52Z"
      />
      <path
        fill="#FFA8A7"
        d="M114.792 34.983c.175.136.427-.3.643-.59a.796.796 0 0 1 .317-.276.613.613 0 0 1 .382-.045.7.7 0 0 1 .352.196.997.997 0 0 1 .233.39c.361.97-.458 2.268-.94 2.694a.763.763 0 0 1-.479.26.703.703 0 0 1-.508-.15l-.127 3.72a3.952 3.952 0 0 1-.946 1.512 2.802 2.802 0 0 1-1.394.757c-1.721.295-1.36-1.504-.799-2.388V39.71a3.206 3.206 0 0 1-1.3.088c-.224-.075-.754-.88-1.098-1.816-.386-1.033-1.032-2.867-.454-5.966.612-3.329 3.41-3.43 5.082-2.089 1.672 1.341 1.036 5.057 1.036 5.057Z"
      />
      <path
        fill="#263238"
        d="M114.792 35.36c.086 0 .427-.712.643-.967.307-.356-.086-3.561-.086-3.561s.307-1.214-.296-2.023c-.602-.809-1.765-.668-2.618-.708a6.284 6.284 0 0 1-3.049-.782c-1.211-.673-1.301-.752-1.452-.07-.152.68-.207 2.233.516 3.275l.602.286s.324.365 1.452.54c1.044.102 2.092.077 3.132-.074.327-.057.395.347.571 1.455.158.976.286 2.625.585 2.63Z"
      />
      <path
        fill="#263238"
        d="m115.346 31.082 1.067-.734a.751.751 0 0 0-.142-.24.567.567 0 0 0-.204-.15.465.465 0 0 0-.46.052.805.805 0 0 0-.299.47c-.048.199-.035.415.038.602Z"
      />
      <path
        fill="#F28F8F"
        d="M111.526 39.71c.73-.177 1.445-.439 2.137-.784a2.11 2.11 0 0 0 .764-.967 3.62 3.62 0 0 1-.433 1.143c-.407.66-2.468 1.148-2.468 1.148v-.54Z"
      />
      <path
        fill="#263238"
        d="M111.912 34.477a.498.498 0 0 0 .021.159c.015.05.038.096.068.135.029.04.065.07.104.091a.256.256 0 0 0 .123.032c.07.008.14-.013.2-.057a.416.416 0 0 0 .139-.193.554.554 0 0 0 .028-.26.506.506 0 0 0-.092-.234.335.335 0 0 0-.18-.125.276.276 0 0 0-.204.028.386.386 0 0 0-.155.17.528.528 0 0 0-.052.254ZM112.245 32.947l.689.479a.657.657 0 0 0 .028-.386.518.518 0 0 0-.187-.304.302.302 0 0 0-.301-.042.376.376 0 0 0-.135.095.508.508 0 0 0-.094.158Z"
      />
      <path
        fill="#F28F8F"
        d="m111.936 37.225-.726.347a.546.546 0 0 0 .075.168.417.417 0 0 0 .121.117.309.309 0 0 0 .296.023.473.473 0 0 0 .219-.268.655.655 0 0 0 .015-.387Z"
      />
      <path
        fill="#263238"
        d="m109.083 33.285.616-.61a.434.434 0 0 0-.109-.133.325.325 0 0 0-.142-.068.293.293 0 0 0-.15.01.357.357 0 0 0-.136.085.57.57 0 0 0-.152.338.64.64 0 0 0 .073.378ZM109.272 34.362c0 .054.009.106.024.155.016.05.04.094.069.132a.315.315 0 0 0 .103.087.254.254 0 0 0 .242 0 .336.336 0 0 0 .103-.087.502.502 0 0 0 .093-.286.497.497 0 0 0-.089-.295.333.333 0 0 0-.104-.091.265.265 0 0 0-.124-.032.298.298 0 0 0-.226.126.482.482 0 0 0-.091.291Z"
      />
      <path fill="#F28F8F" d="m110.886 33.976-.103 2.44-.994-.435 1.097-2.005Z" />
      <path fill="#30B800" d="m102.6 48.661 8.114 2.73 2.395 12.87-7.715-2.862-2.794-12.738Z" />
      <path
        fill="#91E509"
        d="M106.137 57.178c-.142.326-.358.59-.62.756-.261.167-.556.23-.845.18l-.117-.531 1.582-.405ZM107.951 57.583c.034.343.588.976 1.521 1.372 1.06.44 2.688.215 2.688.215l-.145-.642-4.064-.945Z"
      />
      <path
        fill="#455A64"
        d="m104.988 50.526-.069-.396c-.034-.22.441-.229 1.067-.022l1.194.396c.627.21 1.16.554 1.198.774l.069.395-3.459-1.147Z"
        opacity=".43"
      />
      <path
        fill="#37474F"
        d="M104.41 49.035c-.02-.136.172-.171.434-.079l.488.185s-.141-.88.912-.559c.281.053.547.198.771.42.224.223.4.516.509.85l.506.186c.279.1.53.29.565.44l.058.246-4.181-1.407-.062-.282Z"
      />
      <path
        fill="#37474F"
        d="M104.988 49.462c.382.118.54.184.599.518.058.335.093.669.093.669l1.848.624s-.09-.488-.117-.677c-.028-.189.034-.303.344-.206l.024-.15-2.901-.941.11.163Z"
      />
      <path
        fill="#37474F"
        d="m104.923 50.565-.066-.395c-.037-.22.437-.23 1.064-.022l1.194.395c.626.207 1.163.55 1.201.77l.065.395-3.458-1.143Z"
      />
      <path
        fill="#FFA8A7"
        d="m122.369 57.583-2.725-2.696s-5.712 1.372-6.356 1.438c-.643.066-1.153-.747-1.397-1.556-.244-.81-.74-.708-.74-.708s.19 1.029.252 1.354c.061.325-1.429.594-2.285.224-.857-.369-2.099.167-1.332 1.662.767 1.495 2.268 1.662 3.307 1.667a8.717 8.717 0 0 0 1.301-.115c.344-.052.688-.096 1.008-.127 2.126-.194 8-.756 8.967-1.143Z"
      />
      <path
        fill="#F28F8F"
        d="m103.423 52.425.361 1.64c.069.317 1.012.88 1.61 1.1.599.22 1.249.44.967 1.525-.261.98-1.242 1.205-1.741 1.179a1.715 1.715 0 0 1-.907-.55 2.497 2.497 0 0 1-.549-1.073c-.344-1.253-.158-2.594-.295-3.328-.138-.735.258-.928.554-.493Z"
      />
      <path
        fill="#37474F"
        d="M115.824 97.758v-.374c-.193.11-.647 2.313-1.452 3.249-.64.748-1.514 1.614-1.721 2.445-.206.831 1.219 1.345 1.817 1.209.689-.158 1.903-.783 2.206-1.363.303-.58.444-1.438.688-1.864.245-.427.812-.88.947-1.39.04-.46 0-.925-.117-1.363-.093-.462-.196-.963-.317-.923v.286c-.295.292-.659.447-1.032.44-.3.013-1.022-.062-1.019-.352ZM108.773 93.647c-.106 0-.192.193-.423.514a4.64 4.64 0 0 1-1.197 1.1c-.761.487-2.237 1.235-2.908 1.622-.42.242-.399.879.034 1.253.434.374 1.477.633 2.729.273.688-.194 1.573-.999 2.134-.96.56.04 1.613.036 2.03-.329.416-.365.248-1.148.131-1.82-.117-.673-.31-1.851-.499-1.803v.233c-.245.47-1.49.664-2.027.317l-.004-.4ZM20.788 92.068l1.27 2.256a3.497 3.497 0 0 1-1.848.5c-1.135.058-1.448-.439-1.49-.536-.192-.523.107-1.473.19-2.29l1.878.07Z"
      />
      <path
        fill="#F28F8F"
        d="M18.72 89.799s.193 2.05.207 2.489c.014.44 1.566.585 1.861-.22l.045-2.506-2.112.237Z"
      />
      <path
        fill="#91E509"
        d="M18.72 49.43c-1.06 2.111-2.064 3.958-2.284 6.341-.455 5.008 1.032 10.733 1.352 14.435-.602 1.057-.95 2.314-1.002 3.62 0 1.53 1.934 15.973 1.934 15.973s1.404.55 2.113-.237l1.329-26.571-.689-3.236L18.72 49.43Z"
      />
      <path
        fill="#9EFF7A"
        d="M18.72 49.43c-1.06 2.111-2.064 3.958-2.284 6.341-.455 5.008 1.032 10.733 1.352 14.435-.602 1.057-.95 2.314-1.002 3.62 0 1.53 1.934 15.973 1.934 15.973s1.404.55 2.113-.237l1.329-26.571-.689-3.236L18.72 49.43Z"
        opacity=".5"
      />
      <path fill="#30B800" d="m28.517 39.533 6.989 4.287-4.195 12.052-6.517-4.805 3.723-11.534Z" />
      <path fill="#91E509" d="m34.684 44.04-3.62 10.271-5.018-3.693 2.846-10.109 5.792 3.531Z" />
      <path fill="#fff" d="m28.892 40.51 5.792 3.53-3.91 10.381-5.478-3.996 3.596-9.916Z" />
      <path
        fill="#E0E0E0"
        d="m30.152 41.996.134-.37c.075-.202.554-.118 1.07.194l.988.598c.516.307.87.73.798.932l-.138.37-2.852-1.724Z"
      />
      <path
        fill="#F28F8F"
        d="M16.35 43.864c-.51 1.856-1.518 4.802-1.195 5.395.324.594 2.265.466 3.345.414 1.377-.066 2.519-.137 2.519-.137l-.23-3.078h-1.955l.86-3.112-3.345.518Z"
      />
      <path
        fill="#91E509"
        d="M18.307 37.269c-.712 1.495-2.15 6.771-2.15 6.771.4.58.93.986 1.517 1.165a2.453 2.453 0 0 0 1.583.08l1.59-5.057-2.54-2.96Z"
      />
      <path
        fill="#000"
        d="M18.307 37.269c-.712 1.495-2.15 6.771-2.15 6.771.4.58.93.986 1.517 1.165a2.453 2.453 0 0 0 1.583.08l1.59-5.057-2.54-2.96Z"
        opacity=".4"
      />
      <path
        fill="#FFA8A7"
        d="M25.42 38.21c1.25.48 2.01.563 3.39 3.276 1.38 2.713 4.4 8.094 4.7 8.74.416.88.596 1.97.238 2.797-.358.827-1.311.44-2.681-1.104-1.37-1.543-3.95-5.68-3.95-5.68s-2.691-3.874-1.697-8.03ZM24.422 34.692l-.337 3.812-2.946.436.427-5.061 2.856.813Z"
      />
      <path
        fill="#263238"
        d="M18.122 29.121c.244-3.517 1.72-4.973 2.893-5.32 2.33-.703 4.409.378 5.351 2.467.248.55.665 2.607-.165 3.82-.83 1.214-8.08-.967-8.08-.967Z"
      />
      <path
        fill="#F28F8F"
        d="M26.597 30.361s.613 1.32.52 1.469c-.163.12-.341.204-.527.246l.007-1.715Z"
      />
      <path
        fill="#FFA8A7"
        d="M24.739 26.118c.633.48 1.72 1.355 1.868 4.432.155 3.162-.316 4.134-.688 4.52-.237.264-1.455.176-2.064-.11-.747-.36-2.41-1.34-3.125-3.02-.826-1.974-.898-4.53.196-5.448.558-.472 1.201-.755 1.869-.82a3.194 3.194 0 0 1 1.944.446Z"
      />
      <path
        fill="#263238"
        d="M24.925 26.276s.437 2.907-.32 4.666c0 0-.345-1.148-1.115-.88-.771.268-.76 1.561-.193 2.265.144.2.329.344.534.416a.894.894 0 0 0 .626-.012c-.107.905-.438 1.743-.943 2.392a3.472 3.472 0 0 1-2.42-.165c-.778-.346-1.463-.965-1.978-1.787-1.72-2.612-1.27-7.035 1.46-7.985 2.728-.95 4.349 1.09 4.349 1.09Z"
      />
      <path
        fill="#37474F"
        d="M21.986 93.242s1.542-.128 2.605-.299c1.063-.171 1.906.492 1.655 1.055-.251.563-.836 1.025-1.748 1.416-.781.339-1.72.58-2.533.88-.812.298-1.376.685-1.848.479-.526-.233-.753-.502-.708-.994.114-.7.257-1.393.426-2.075l2.151-.462Z"
      />
      <path
        fill="#FFA8A7"
        d="m20.283 91.061-.554 3.109s-.052.378 1.214.62c1.267.241 3.256-.845 3.596-1.069.34-.224.379-.66-.867-.572-1.246.088-1.686.093-1.686.093l.416-1.38-2.12-.801Z"
      />
      <path
        fill="#91E509"
        d="m22.161 62.991-.688-3.236a14.999 14.999 0 0 1-3.785-1.922s.716 1.667 2.753 2.577l1.07 4.16.093 9.515.557-11.094Z"
      />
      <path
        fill="#91E509"
        d="M18.72 49.43c-.567 1.184-1.923 3.21-1.638 5.154.345 2.361 1.346 4.014 4.38 5.17l2.85 13.192s-1.483 1.758-1.937 3.46c-.454 1.702-2.092 14.668-2.092 14.668s.505 1.055 2.105.8c0 0 5.578-16.268 5.974-17.328.472-1.067.639-2.31.472-3.517-.176-1.71-2.065-20.385-2.065-20.385l-8.049-1.213Z"
      />
      <path
        fill="#9EFF7A"
        d="M18.72 49.43c-.567 1.184-1.923 3.21-1.638 5.154.345 2.361 1.346 4.014 4.38 5.17l2.85 13.192s-1.483 1.758-1.937 3.46c-.454 1.702-2.092 14.668-2.092 14.668s.505 1.055 2.105.8c0 0 5.578-16.268 5.974-17.328.472-1.067.639-2.31.472-3.517-.176-1.71-2.065-20.385-2.065-20.385l-8.049-1.213Z"
        opacity=".5"
      />
      <path
        fill="#30B800"
        d="M18.204 37.541c.22-.747.922-1.371 1.556-1.151 1.985.69 5.072 1.6 5.66 1.82 0 0-.261 5.716 2.023 7.422-.106 1.086-.688 4.982-.688 4.982s-2.753 1.706-5.01.879c-2.257-.827-3.025-2.049-3.025-2.049l1.133-2.357c-.241-.77-1.177-3.368-1.78-5.786a9.012 9.012 0 0 1 .131-3.76Z"
      />
      <path
        fill="#000"
        d="M18.204 37.541c.22-.747.922-1.371 1.556-1.151 1.985.69 5.072 1.6 5.66 1.82 0 0-.261 5.716 2.023 7.422-.106 1.086-.688 4.982-.688 4.982s-2.753 1.706-5.01.879c-2.257-.827-3.025-2.049-3.025-2.049l1.133-2.357c-.241-.77-1.177-3.368-1.78-5.786a9.012 9.012 0 0 1 .131-3.76Z"
        opacity=".2"
      />
      <path
        fill="#91E509"
        d="M19.543 46.146a18.503 18.503 0 0 0 2.949 1.86c-.927-.023-1.835-.343-2.64-.932l-.31-.928Z"
        opacity=".25"
      />
      <path
        fill="#263238"
        d="M20.128 24.584s-.943-2.054-3.252-1.522c-2.31.532-3.245 3.663-3.21 5.646.034 1.983.553 3.368-.837 5.716a4.645 4.645 0 0 0-.566 1.562 5.16 5.16 0 0 0-.008 1.722c.093.568.281 1.104.55 1.571.27.468.615.856 1.012 1.138 1.854 1.32 2.305-.976 1.875-2.937-.495-2.247 1.346-2.638 1.838-4.34.492-1.701-.475-5.276.144-6.49.688-1.319 1.617-1.134 2.065-.686l.389-1.38Z"
      />
      <path
        fill="#30B800"
        d="M25.42 38.21c.617.167 1.22.412 1.8.73.757.475 3.71 6.397 3.71 6.397s-.21 2.23-2.626 3.113l-1.428-2.304s-2.1-4.005-1.456-7.936Z"
      />
      <path
        fill="#000"
        d="M25.42 38.21c.617.167 1.22.412 1.8.73.757.475 3.71 6.397 3.71 6.397s-.21 2.23-2.626 3.113l-1.428-2.304s-2.1-4.005-1.456-7.936Z"
        opacity=".1"
      />
      <path
        fill="#91E509"
        d="M27.296 46.84a10.181 10.181 0 0 1-1.408-3.226s0 2.559 1.318 3.882l.09-.655Z"
      />
      <path
        fill="#000"
        d="M27.296 46.84a10.181 10.181 0 0 1-1.408-3.226s0 2.559 1.318 3.882l.09-.655Z"
        opacity=".4"
      />
      <path
        fill="#455A64"
        d="M30.403 40.404c.045-.128.255-.119.468 0l.385.242s.3-.796 1.194-.317c.895.48.62 1.398.62 1.398l.41.269c.226.145.378.369.343.5l-.068.234-3.442-2.11.09-.216Z"
      />
      <path
        fill="#455A64"
        d="M30.754 40.905c.323.185.447.273.344.59-.103.316-.251.62-.251.62l1.52.931s.162-.44.231-.633.193-.268.434-.118l.103-.132-2.409-1.43.028.172Z"
      />
      <path
        fill="#455A64"
        d="m30.124 41.886.138-.37c.075-.206.554-.118 1.07.194l.988.598c.512.308.87.725.794.932l-.137.37-2.853-1.724Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h158v150H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default DevLogo
